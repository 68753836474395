import { useNavigate } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

const LandingPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="container">
                <nav>
                    <div className="logo">
                        <img alt="" src="imgs/DeptApparel.png" alignment="" class="dept-logo" />
                    </div>
                </nav>
            </div>
            <div class="container text-center mt-5">
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn btn-primary btn-lg btn-dark" onClick={() => {
                            navigate('/');
                        }}>Home</button>
                    </div>
                    <div class="col">
                        <button type="button" class="btn btn-primary btn-lg btn-dark" onClick={() => {
                            navigate('/register');
                        }}>Register</button>
                    </div>
                    <div class="col">
                        <button type="button" class="btn btn-primary btn-lg btn-dark" onClick={() => {
                            navigate('/flush-data');
                        }}>Flush Data</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LandingPage;