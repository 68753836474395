// Modal as a separate component
import { useEffect, useRef } from "react";

function Modal({ openModal, closeModal, children, classname, showCloseIcon }) {
    const ref = useRef();

    useEffect(() => {
        if (openModal) {
            ref.current?.showModal();
        } else {
            ref.current?.close();
        }

    }, [openModal]);

    return (
        <dialog
            ref={ref}
            onCancel={closeModal}
            className={classname}
        >
            <div className="popupHead">
                <h2 className="popupHeading">Welcome!</h2>
                {
                    showCloseIcon ?

                        <div onClick={closeModal} className="CloseIcon">
                            <svg width="100" height="100" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="menu-open-close-button__icon">
                                <g class="close">
                                    <path class="close__cross" d="M12.78 12.781L27.216 27.22M27.217 12.781L12.779 27.22" stroke="currentColor" stroke-width="1.3"></path>
                                </g>
                            </svg>
                        </div>
                        : <></>
                }
            </div>
            {children}
        </dialog>
    );
}

export default Modal;