import React, { useState } from 'react'
import './notify.css'
import Modal from '../modal/modal';

function Notify({ isShow, sessionId, analyticsData }) {
  const [Toast, setToast] = useState(false);
  const sessionIdNumber = parseInt(sessionId.join(''));
  /*  setTimeout(() => {
     // ref.current?.close();
     setToast(false)
   }, 10000); */


  function sessionEnd(sessionId, mostViewdproductId, mostViewdproductName, mostViewdhoverTime, mostViewdproductPrice, mostViewdproductImg) {
    window.adobeDataLayer.push({
      "event": "session_end",
      "user": {
        "sessionId": sessionId
      },
      "mostViewdproduct": {
        "mostViewdproductId": mostViewdproductId,
        "mostViewdproductName": mostViewdproductName,
        "mostViewdhoverTime": mostViewdhoverTime,
        "mostViewdproductPrice": mostViewdproductPrice,
        "mostViewdproductImg": mostViewdproductImg
      }
    });
    deleteAllCookies();
  }

  function handlestopSession() {
    setToast(true);
    setTimeout(() => {
      setToast(false);
      window.location.reload();
    }, 10000);
    const groupedData = analyticsData.reduce((acc, curr) => {
      const { productId, hoverTime, ...rest } = curr.product;
      acc[productId] = acc[productId] || { ...rest, mostViewdhoverTime: 0 };
      acc[productId].mostViewdhoverTime += hoverTime || 0;
      return acc;
    }, {});

    let maxTime = 0;
    let maxProduct = '';

    // Find the object with the maximum mostViewdhoverTime
    for (const key in groupedData) {
      if (groupedData.hasOwnProperty(key)) {
        const time = groupedData[key].mostViewdhoverTime;
        if (time > maxTime) {
          maxTime = time;
          maxProduct = key;
        }
      }
    }

    const resultSessionEnd = {
      event: "session_end",
      user: { sessionId: sessionIdNumber },
      product: {
        ...groupedData[maxProduct],
        mostViewdproductId: groupedData[maxProduct]
      }
    };

    console.log(resultSessionEnd);

    sessionEnd(resultSessionEnd.user.sessionId, resultSessionEnd.product['mostViewdproductId'], resultSessionEnd.product['productName'], resultSessionEnd.product['mostViewdhoverTime'], resultSessionEnd.product['productPrice'], resultSessionEnd.product['productImg'])
  }

  function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
  return (
    <>
      <div className={`notification ${isShow ? 'dsp-show' : 'dsp-none'}`}>
        <div className="notification-parent">
          {/* <p className='notification-text'>You can close session</p> */}
          <button className='PrimaryBtn' onClick={handlestopSession}>Stop Session</button>
        </div>
      </div>
      <div className={`toastComponent ${Toast ? "dsp-show" : "dsp-none"}`}>
        <p className='toastText'>Your Session is closed</p>
      </div>
    </>
  )
}

export default Notify