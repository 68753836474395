import QRCode from 'react-qr-code';
import '../SaveEmail/SaveEmail.css';

function ScanQR() {
    return (
        <div className="container">

            <div className="SaveEmailParent">

                <div className="formParent">
                    <div className="brandLogo">
                        <img src="./imgs/dept-logo.png" alt="deptlogo" className='deptlogo' />
                        <h3 className='headingText'>Vision Pro Demo</h3>
                    </div>
                    <QRCode
                        size={60}
                        style={{ height: "50%", maxWidth: "60%", width: "60%" }}
                        value={"http://visionpro.teknopoint.in/#/register"}
                        viewBox={`0 0 60 60`}
                    />
                </div>
            </div>

        </div>
    )
}

export default ScanQR;