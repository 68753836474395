import { useEffect, useState } from "react";

import Navigation from "../../Navigation/Nav";
import Products from "../../Products/Products";
import Card from "../../components/Card";
import "../../index.css";
import axios from "axios";
import FormComp from "../../components/form/FormComp";
import Modal from '../../components/modal/modal';
import CardModal from '../../components/cardModal';
import genrateRandomFourDigit from "../../db/data";
import { flushSync } from "react-dom";

var counter = 0;

function Home() {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [productData, setproductData] = useState([]);
  const [modal, setModal] = useState(true);
  const [modalClose, setModalClose] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [sessionId, setSessionId] = useState([]);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [selectedCard, setSelectedCard] = useState({});
  const [showCardPopup, setShowCardPopup] = useState(false);


  useEffect(() => {


    /*  let userId = genrateRandomFourDigit();
     userId.then(response => {
         setSessionId(response.toString().split(""));
     })
     window.adobeDataLayer = window.adobeDataLayer || []; */


    /*   let data = JSON.stringify({
        query: `{
              products(search: "") {
                total_count
                items {
                  name
                  sku
                  description {
                    html
                  }
                  price_range {
                    maximum_price {
                      final_price {
                        currency
                        value
                      }
                    }
                  }
                  image {
                    url
                  }
                   reviews {
                          items {
                            nickname
                            summary
                          }
                        }
                  type_id
                  ... on ConfigurableProduct {
                    variants {
                      product {
                        id
                        name
                        sku
                        description {
                          html
                        }
                        price_range {
                          maximum_price {
                            final_price {
                              currency
                              value
                            }
                          }
                        }
                        image {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }`,
        variables: {}
      });
  
      // let config = {
      //     method: 'post',
      //     maxBodyLength: Infinity,
      //     url: 'http://localhost:5500/products',
      //     headers: {
      //         'Content-Type': 'application/json',
      //     },
      // };
  
      // below code use when make build
  
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/graphql',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
  
      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setproductData(response.data.data.products.items);
        })
        .catch((error) => {
          console.log(error);
        }); */
  }, [])


  let callProductData = () => {
    let data = JSON.stringify({
      query: `{
            products(search: "") {
              total_count
              items {
                name
                sku
                description {
                  html
                }
                price_range {
                  maximum_price {
                    final_price {
                      currency
                      value
                    }
                  }
                }
                image {
                  url
                }
                 reviews {
                        items {
                          nickname
                          summary
                        }
                      }
                type_id
                ... on ConfigurableProduct {
                  variants {
                    product {
                      id
                      name
                      sku
                      description {
                        html
                      }
                      price_range {
                        maximum_price {
                          final_price {
                            currency
                            value
                          }
                        }
                      }
                      image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }`,
      variables: {}
    });

    // let config = {
    //     method: 'post',
    //     maxBodyLength: Infinity,
    //     url: 'http://localhost:5500/products',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    // };

    // below code use when make build

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/graphql',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setproductData(response.data.data.products.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // ----------- Input Filter -----------
  const [query, setQuery] = useState("");

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    console.log('====================================');
    console.log(event.target.value);
    console.log('====================================');
  };

  const filteredItems = productData.filter(
    (product) => product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
  );

  console.log('==============filtered item======================');
  console.log(filteredItems);
  console.log('====================================');


  // ----------- Radio Filtering -----------
  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  // ------------ Button Filtering -----------
  const handleClick = (event) => {
    setSelectedCategory(event.target.value);
  };


  const handleCardClick = (product = {}) => {
    setSelectedCard(product)
    setTimeout(() => {
      interval(true);
      setShowCardPopup(true)
    }, 10)
  }

  function filteredData(products, selected, query) {
    let filteredProducts = products;

    // Filtering Input Items
    if (query) {
      filteredProducts = filteredItems;
    }


    return filteredProducts ? filteredProducts.map((product, index) => {
      return <Card
        key={Math.random()}
        img={product.image.url}
        title={product.name}
        sku={product.sku}
        star={""}
        reviews={"0 reviews"}
        prevPrice={"$ " + product.price_range.maximum_price.final_price.value + 10}
        newPrice={"$ " + product.price_range.maximum_price.final_price.value}
        updateAnalyticsData={(data) => { setAnalyticsData([...analyticsData, ...data]) }}
        sessionId={sessionId}
        handleCardClick={handleCardClick}
      />
    }) : <div>Loading...</div>
  }


  const interval = (isStart) => {
    let counterInterval;
    if (isStart) {
      counterInterval = setInterval(function () {
        counter += 1;
      }, 1000);
    } else {
      clearInterval(counterInterval);
      counter = 0;
    }
  }

  const closeCardPopup = () => {
    console.log("Counter: ", selectedCard);
    productEyeTracking(sessionId.join(''), selectedCard.sku, selectedCard.name, counter, selectedCard.price_range.maximum_price.final_price.value, selectedCard.image.url);
    interval(false);
  }

  function productEyeTracking(sessionId, productId, productName, hoverTime, productPrice, productImg) {
    var hoverTime = hoverTime ? hoverTime : 0;
    window.adobeDataLayer.push({
      "event": "productTracking",
      "user": {
        "sessionId": sessionId
      },
      "product": {
        "productId": productId,
        "productName": productName,
        "hoverTime": hoverTime,
        "productPrice": productPrice,
        "productImg": productImg
      }
    })

    const trackedData = [{
      "event": "productTracking",
      "user": {
        "sessionId": sessionId
      },
      "product": {
        "productId": productId,
        "productName": productName,
        "hoverTime": hoverTime,
        "productPrice": productPrice,
        "productImg": productImg
      }
    }]
    setAnalyticsData([...analyticsData, ...trackedData])
    // console.log(UserContext);
  }


  return (
    <>
      <div className="container-1">
        {/* <button onClick={() => setModal(true)} className="popupBtn"> Open modal</button> */}
        <Modal modalClose={modalClose} openModal={modal} showCloseIcon={showCloseIcon} closeModal={() => setModal(false)} classname="popup">
          <FormComp sessionId={sessionId} isModalClose={() => { setModal(false) }} showCloseIconFn={() => { setShowCloseIcon(true) }} setSession={(sessionId) => {
            setSessionId(sessionId);
            callProductData(sessionId)
            /* filteredData(productData, selectedCategory, query); */
          }} />
        </Modal>
        <div className="fixedHead">
          <Navigation query={query} handleInputChange={handleInputChange} sessionId={sessionId} analyticsData={analyticsData} isShow={!modal} />

        </div>
        {/* <Recommended handleClick={handleClick} /> */}
        <div className="productListPage">
          {/* <Sidebar handleChange={handleChange} /> */}
          {/* <Products result={result} /> */}

          <section className="card-container">
            {
              productData ? productData.map((product, index) => {
                return <Card
                  key={Math.random()}
                  img={product.image.url}
                  title={product.name}
                  sku={product.sku}
                  star={""}
                  reviews={"0 reviews"}
                  prevPrice={"$ " + product.price_range.maximum_price.final_price.value + 10}
                  newPrice={"$ " + product.price_range.maximum_price.final_price.value}
                  updateAnalyticsData={(data) => { setAnalyticsData([...analyticsData, ...data]) }}
                  sessionId={sessionId}
                  handleCardClick={() => handleCardClick(product)}
                />
              }) : <div>Loading...</div>
            }
          </section>
        </div>
        <CardModal openModal={showCardPopup} closeModal={() => {
          closeCardPopup();
          setShowCardPopup(false);
        }} product={selectedCard} />
      </div>
    </>
  );
}

export default Home;
