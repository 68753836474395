import "./index.css";
import { BrowserRouter as Router, Route, Switch, Routes, HashRouter } from 'react-router-dom';
import Home from "./Screens/Home/Home";
import RegisterEmail from "./Screens/SaveEmail/RegisterEmail";
import ScanQR from "./Screens/ScanQR/ScanQr";
import LandingPage from "./LandingPage";
import FlushData from "./FlushData";


function App() {

  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<RegisterEmail />} />
          <Route path="/scan-qr" element={<ScanQR />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/flush-data" element={<FlushData />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
