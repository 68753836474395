import { BsFillBagFill } from "react-icons/bs";

const Card = ({ 
  img, title, star, reviews, prevPrice, newPrice, sku, updateAnalyticsData, sessionId, handleCardClick }) => {


  const sessionIdNumber = parseInt(sessionId.join(''));


  let mouseEntertime;
  let mouseLeavetime;
  let totalHoveredTime;
  // let ProductCaptured = {
  //   productId:'',
  //   productName:'',
  //   hoverTime:'',
  //   userName:'',
  //   emailId:'',
  //   loginStatus: true,
  //   userID: ''
  // }
  let ProductCaptured = {
    productId: '',
    productName: '',
    hoverTime: '',
    productPrice: '',
    productImg: '',
    sessionId: '',
  }

  function handelMouseEnter(e) {
    mouseEntertime = e.timeStamp;
  }

  function handelMouseLeave(e) {
    mouseLeavetime = e.timeStamp;
    console.log('===============handelMouseLeave=====================');
    console.log(e.currentTarget?.attributes['skuid']?.value);
    console.log(mouseLeavetime - mouseEntertime + '====================================');
    totalHoveredTime = mouseLeavetime - mouseEntertime;
    // var sessionObj = JSON.parse(sessionStorage.getItem('dataMapping'));
    ProductCaptured = {
      productId: e.currentTarget?.attributes['skuid']?.value,
      productName: e.currentTarget?.attributes['productname']?.value,
      hoverTime: totalHoveredTime,
      productPrice: e.currentTarget?.attributes['productprice']?.value,
      productImg: e.currentTarget?.attributes['productimg']?.value,
      sessionId: sessionIdNumber
      // userName: sessionObj?.name,
      // emailId: sessionObj?.emailId,
    }
    productEyeTracking(ProductCaptured.sessionId, ProductCaptured.productId, ProductCaptured.productName, ProductCaptured.hoverTime, ProductCaptured.productPrice, ProductCaptured.productImg)
  }

  // function productmouseHover(productId, productName, hoverTime, userName, emailId, loginStatus = true, userID = '') {

  //     window.adobeDataLayer.push({
  //       "event": "productmouseHover",
  //       "data": {
  //         "productId": productId,
  //         "productName": productName,
  //         "hoverTime": hoverTime,
  //         "loginStatus": loginStatus,
  //         "userID": userID,
  //         "emailId": emailId,
  //         "userName": userName,
  //       }
  //     });
  //   }


  function productEyeTracking(sessionId, productId, productName, hoverTime, productPrice, productImg) {
    var hoverTime = hoverTime ? hoverTime : 0;
    window.adobeDataLayer.push({
      "event": "productTracking",
      "user": {
        "sessionId": sessionId
      },
      "product": {
        "productId": productId,
        "productName": productName,
        "hoverTime": Number(hoverTime) / 1000,
        "productPrice": productPrice,
        "productImg": productImg
      }
    })

    const trackedData = [{
      "event": "productTracking",
      "user": {
        "sessionId": ProductCaptured.sessionId
      },
      "product": {
        "productId": productId,
        "productName": productName,
        "hoverTime": hoverTime / 1000,
        "productPrice": productPrice,
        "productImg": productImg
      }
    }]
    updateAnalyticsData(trackedData);
    // console.log(UserContext);
  }

  return (
    <>
      <section className="card" style={{ width: "30%", padding: "20px", margin: "5px  " }} onMouseEnter={handelMouseEnter} onMouseLeave={handelMouseLeave} skuid={sku} productname={title} productprice={newPrice} productimg={img} onClick={handleCardClick}>
        <img src={img} alt={title} className="card-img" />
        <div className="card-details">
          <h3 className="card-title">{title}</h3>
          <section className="card-reviews">
            {star} {star} {star} {star}
            <span className="total-reviews">{reviews}</span>
          </section>
          <section className="card-price">
            <div className="price">
              <del>{prevPrice}</del> {newPrice}
            </div>
            <div className="bag">
              <BsFillBagFill className="bag-icon" />
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Card;
