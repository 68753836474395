import { FiHeart } from "react-icons/fi";
import { AiOutlineShoppingCart, AiOutlineUserAdd } from "react-icons/ai";
import "./Nav.css";
import Notify from "../components/notifiy";

const Nav = ({ handleInputChange, query , analyticsData ,sessionId ,isShow }) => {
  return (
    <nav>
      <div className="logo">
      <img alt="" src="imgs/DeptApparel.png" alignment="" class="dept-logo" />
      </div>
      <div className="nav-container">
        <svg width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" class="svg-icon search-field"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.98 15.395a6.294 6.294 0 111.414-1.414l4.602 4.601-1.414 1.414-4.602-4.601zm.607-5.101a4.294 4.294 0 11-8.587 0 4.294 4.294 0 018.587 0z" fill="currentColor"></path></svg>
        <input
          className="search-input"
          type="text"
          onChange={handleInputChange}
          value={query}
          placeholder="What are you looking for?"
        />
      </div>
      <div className="profile-container">
      <Notify sessionId={sessionId} analyticsData={analyticsData} isShow={isShow} />
      </div>
    </nav>
  );
};

export default Nav;
