// Modal as a separate component
import { useEffect, useRef } from "react";
import "./CardModal.css"

function CardModal({ openModal, closeModal, product }) {
  const ref = useRef();

  useEffect(() => {
    if (openModal) {
      ref.current?.showModal();
    } else {
      ref.current?.close();
    }
  }, [openModal]);

  const img=product?.image?.url
  const title=product?.name
  const star=""
  const reviews="0 reviews"
  const prevPrice="$ " + product?.price_range?.maximum_price?.final_price?.value + 10
  const newPrice="$ " + product?.price_range?.maximum_price?.final_price?.value

  return (
    <dialog
      ref={ref}
      onCancel={closeModal}
      className="card-popup"
    >
      <div className="popup-wrapper">
        <div className="CloseIcon" onClick={closeModal}>
          <svg width="100" height="100" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="menu-open-close-button__icon">
            <g class="close">
              <path class="close__cross" d="M12.78 12.781L27.216 27.22M27.217 12.781L12.779 27.22" stroke="currentColor" stroke-width="1.3"></path>
            </g>
          </svg>
        </div>
        <img src={img} alt={title} className="card-img" />
        <div className="card-details">
          <h3 className="card-title">{title}</h3>
          <section className="card-reviews">
            {star} {star} {star} {star}
            <span className="total-reviews">{reviews}</span>
          </section>
          <section className="card-price">
            <div className="price">
              <del>{prevPrice}</del> {newPrice}
            </div>
            <div className="bag">
              {/* <BsFillBagFill className="bag-icon" /> */}
            </div>
          </section>
        </div>
      </div>

    </dialog>
  );
}

export default CardModal;