import { React, useEffect, useState } from 'react'
import './FormComp.css';
import axios from 'axios';

function FormComp({ isModalClose, showCloseIconFn, setSession }) {



    const [name, setName] = useState("");
    const [email, setEmail] = useState([]);

    const [userEmailSelect, setUserEmailSelect] = useState('bhuwanesh.pandey@deptagency.com');
    const [selectedUserData, setSelectedUserData] = useState({
        emailId: 'bhuwanesh.pandey@deptagency.com',
        firstName: "Bhuwanesh",
        lastName: "Pandey",
    });

    const [sessionId, setSessionId] = useState([]);

    const [isToggled, setIsToggled] = useState(false);
    // const [showErrorMessage, setShowErrorMessage] = useState("");


    useEffect(() => {
        getEmailIds();
    }, []);

    function getEmailIds() {
        let data = '';
        let config = {
            method: 'get',
            url: 'http://20.102.76.124:8000/get-emails',
            headers: {},
            data: data
        };
        axios.request(config)
            .then((response) => {
                setEmail(response.data.reverse());
            })
            .catch((error) => {
                console.log(error);
            });
    }


    function sessionStart(session) {
        window.adobeDataLayer.push({
            "event": "session_start",
            "user": {
                "sessionId": session,
                "fullName": selectedUserData.firstName + " " + selectedUserData.lastName,
                ...selectedUserData
            }
        });
        setSession(sessionId)
    }

    const handleChange = () => {
        setIsToggled(!isToggled);
        showCloseIconFn(true)
        const sessionIdNumber = parseInt(sessionId.join(''));
        sessionStart(sessionIdNumber)
        localStorage.setItem("sessionID", sessionIdNumber);
        setTimeout(() => {
            isModalClose(true)
        }, 5000);

    };

    function getSessionData() {
        return sessionStorage ? sessionStorage.getItem('dataMapping') : null;
    }

    function setSessionData(data) {
        if (getSessionData()) {
            sessionStorage.removeItem('dataMapping');

        }
        sessionStorage.setItem('dataMapping', JSON.stringify(data));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        var data = {
            name: name,
            emailId: email
        }
        setSessionData(data);
        alert(`The name you entered was: ${name}`);
        document.getElementById('mypopover').hidePopover()
    }


    return (
        <div className={`formParent ${isToggled ? "SessionStart" : ""}`}>
            <p className={`heading ${isToggled ? 'dsp-none' : 'dsp-show'}`}>Please Select Email ID</p>
            <div className={`selectParent ${isToggled ? 'dsp-none' : 'dsp-show'}`} >
                <select className='selectOptions' name="email" onChange={(event) => {
                    setSessionId(JSON.parse(event.currentTarget.options[event.currentTarget.options.selectedIndex].dataset.obj).sessionId.toString().split(''));
                    setSelectedUserData(JSON.parse(event.currentTarget.options[event.currentTarget.options.selectedIndex].dataset.obj));
                    setUserEmailSelect(event.currentTarget.value);
                }}>
                    <option value="">Select</option>
                    {
                        email.length > 0 ? email.map((e) => {
                            return <option value={e.emailId} data-obj={JSON.stringify(e)}>{e.emailId}</option>
                        }) : <></>
                    }
                </select>
                <div>
                    <p className='refreshBtn' onClick={() => {
                        getEmailIds();
                    }}><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24"><path d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z" /></svg></p>
                </div>
                {/* <p className="error">{showErrorMessage}</p> */}
            </div>

            <div className={`${isToggled ? "codemain" : "dsp-none"}`}>
                <div className='codeParent'>
                    {sessionId.map((e) => {
                        return <p className='codeParent-block'>{e}</p>
                    })}
                </div>
                <p className='disclaimer'>Please remember this code for further analytics usage </p>
            </div>
            <button className={`PrimaryBtn ${isToggled ? 'dsp-none' : 'dsp-show'}`} onClick={handleChange}>START SESSION</button>
        </div>

    )
}

export default FormComp