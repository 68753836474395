import { useState } from 'react';
import axios from 'axios';
import './SaveEmail.css'

function RegisterEmail() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [Toast, setToast] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    setTimeout(() => {
      setToast(false)
      setEmail('')
      setFirstName('');
      setLastName('');
    }, 5000);

    var randomNumber = Math.floor(Math.random() * 9000 + 1000);

    let requestObj = JSON.stringify({
      "data": {
        "emailId": email,
        "firstName": firstName,
        "lastName": lastName,
        "sessionId": randomNumber,
        "fullName": firstName + " " + lastName
      }
    });
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'http://20.102.76.124:8000/set-email',
      headers: {
        'Content-Type': 'application/json'
      },
      data: requestObj
    };
    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        var reqObj = JSON.parse(requestObj).data;
        sessionRegistration(reqObj.sessionId, reqObj.firstName, reqObj.lastName, reqObj.emailId);
        // setEmail(JSON.stringify(response.data))
        setToast(true)
      })
      .catch((error) => {
        console.log(error);
      });

  }

  function sessionRegistration(sessionId, firstName, lastName, emailId) {
    window.adobeDataLayer.push({
      "event": "sessionRegistration",
      "profileData": {
        "visionProId": sessionId,
        "lastName": lastName,
        "firstName": firstName,
        "fullName": firstName + ' ' + lastName,
        "personalEmail": emailId,
        "_id": Date.now()
      }
    })
  }

  return (
    <>
      <div className="container">

        <div className="SaveEmailParent">

          <div className="formParent">
            
            <form className='formbody' onSubmit={handleSubmit}>
              <div className="brandLogo">
                <img src="./imgs/dept-logo.png" alt="deptlogo" className='deptlogo' />
                <h3 className='headingText'>Vision Pro Demo</h3>
              </div>
              <h3 className='headingText'>Registration</h3>
              <label className='formLabel'>
                <input
                  type="Email"
                  value={email}
                  placeholder='Enter Email ID'
                  className='formInput'
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>
              <label className='formLabel'>
                <input
                  type="input"
                  placeholder='Enter First Name'
                  value={firstName}
                  className='formInput'
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </label>
              <label className='formLabel'>
                <input
                  type="input"
                  placeholder='Enter Last Name'
                  value={lastName}
                  className='formInput'
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </label>
              <input className='PrimaryBtn' type="submit" />
            </form>
          </div>
        </div>

      </div>
      <div className="SaveEmailParentOverlay">
        <div className={`toastComponent ${Toast ? "dsp-show" : "dsp-none"}`}>
              <p className='toastText'>Your Email is Registered</p>
            </div>
      </div>
    </>
  );
}

export default RegisterEmail;
