import axios from 'axios';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
const FlushData = () => {

    const [showMessage, setShowMessage] = useState("");

    const flushDataApiCall = (password) => {
        if (password) {
            setShowMessage("");
            let config = {
                method: 'post',
                url: 'http://20.102.76.124:8000/flush-data',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    password
                }
            };
            axios.request(config)
                .then((response) => {
                    // setEmail(response.data.reverse());
                    setShowMessage(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setShowMessage("Please Enter Password");
        }
    }
    return (
        <div className="container">
            <nav>
                <div className="logo">
                    <img alt="" src="imgs/DeptApparel.png" alignment="" class="dept-logo" />
                </div>
            </nav>

            <div className="container mt-5">
                <div className="row">
                    <h1>Flush Data</h1>
                </div>
                <div className="d-flex flex-row justify-content-center">
                    <div class="card" style={{ "width": "40rem" }}>
                        <div class="card-body">
                            <label for="inputPassword" class="col-sm-12 col-form-label">Enter Password</label>
                            <div class="col-sm-12">
                                <input type="password" class="form-control" id="inputPassword" />
                            </div>

                            <div className="mt-5 justify-content-center">
                                <button type="button" class="btn btn-dark" onClick={() => {
                                    flushDataApiCall(document.getElementById('inputPassword').value);
                                }}>Flush Data</button>
                            </div>
                            <div className='mt-5'>
                                <p>{showMessage}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FlushData;